<template>
  <div>
    <div
      class="e-main pt-5 text-dark"
      v-if="entity_type === 'events'"
      @click="viewTourServices"
      style="cursor: pointer"
    >
      <div class="item">
        <span>Check our Events</span>
        <span>You can checkout the events we have</span>
      </div>
      <div class="leftChe">
        <i class="fe fe-chevron-right text-gold-colored"></i>
      </div>
    </div>

    <div
      class="e-main pt-5 text-dark"
      v-if="entity_type === 'tour_sight'"
      @click="viewTourServices"
      style="cursor: pointer"
    >
      <div class="item">
        <span>Check our Attraction Site </span>
        <span>You can checkout the best attractions we have</span>
      </div>
      <div class="leftChe">
        <i class="fe fe-chevron-right text-gold-colored"></i>
      </div>
    </div>

    <div
      class="e-main pt-5 text-dark"
      v-if="entity_type === 'tour_operator'"
      @click="viewTourServices"
      style="cursor: pointer"
    >
      <div class="item">
        <span>Check our available tours</span>
        <span>You can checkout the best tours we have</span>
      </div>
      <div class="leftChe">
        <i class="fe fe-chevron-right text-gold-colored"></i>
      </div>
    </div>

    <div
      class="e-main pt-5 text-dark"
      v-if="entity_type === 'food_and_drinks'"
      @click="viewTourServices"
      style="cursor: pointer"
    >
      <div class="item">
        <span>Check our Menu</span>
        <span>You can checkout our menu</span>
      </div>
      <div class="leftChe">
        <i class="fe fe-chevron-right text-gold-colored"></i>
      </div>
    </div>
    <div
      class="e-main pt-5 text-dark"
      v-if="entity_type === 'transport'"
      @click="viewTourServices"
      style="cursor: pointer"
    >
      <div class="item">
        <span>Check our available Cars</span>
        <span>You can checkout the best cars we have</span>
      </div>
      <div class="leftChe">
        <i class="fe fe-chevron-right text-gold-colored text-gold-colored"></i>
      </div>
    </div>

    <div
      class="e-main pt-5 text-dark"
      v-if="entity_type === 'hotel_and_accommodations'"
      @click="viewTourServices"
      style="cursor: pointer"
    >
      <div class="item">
        <span>Check our available Rooms and Services</span>
        <span>Exquisite rooms and services for all occasions</span>
      </div>
      <div class="leftChe">
        <i class="fe fe-chevron-right text-gold-colored item"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity_type: {
      type: String,
      default: "",
    },
    entity_id: {
      type: String,
      required: true,
      default: "",
    },
  },
  methods: {
    viewTourServices() {
      const entityDetailRoute = this.$route.path;
      let entityServiceRoute = `${entityDetailRoute}/services`;

      if (!entityServiceRoute.startsWith("/tourist")) {
        entityServiceRoute = `/tourist/gta-facilities/${entityServiceRoute}`;
      }

      if (entityServiceRoute.includes("events-organizers")) {
        entityServiceRoute = entityServiceRoute.replace(
          "events-organizers",
          "events"
        );
      }

      console.log("entityServiceRoute", entityServiceRoute);
      this.$router.push(entityServiceRoute);
    },
  },
};
</script>

<style>
.e-main {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}
.item {
  padding-right: 10px;
  margin: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.leftChe {
  font-size: 28px;
  float: right;
}
.text-gold-colored {
  color: #faad15;
}
</style>
