<template>
  <div>
    <a-modal v-model="visible" :title="null" :footer="null">
      <div class="card-body">
        <h4>General Policy</h4>
        <p>{{ information.general_policy || "N/A" }}</p>
        <hr />

        <h4>Refund Policy</h4>
        <p>{{ information.refund_policy || "N/A" }}</p>
        <hr />

        <h4>Cancellation Policy</h4>
        <p>{{ information.cancellation_policy || "N/A" }}</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { get } from "lodash";

export default {
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    facilityData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    showModal: {
      handler(show) {
        console.log("showModal", show);
        this.visible = show;
      },
      immediate: true,
    },
    visible(show) {
      if (!show) {
        this.$emit("close-modal", show);
      }
    },
  },
  computed: {
    information() {
      return get(this.facilityData, "information", {});
    },
  },
};
</script>

<style scoped>
.transaction-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
</style>
