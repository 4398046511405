<template>
  <div style="padding: 10px">
    <div class="e-main">
      <terms-and-conditions
        :show-modal="showTermsAndConditions"
        :facility-data="entity"
        @close-modal="showTermsAndConditions = $event"
      />

      <div class="col-lg-10 col-md-10 col-sm-10">
        <h3 class="font-weight-bold font-size-24 text-black mb-1">
          {{ getValueFromSource(entity, "information.name") }}
        </h3>
        <h3
          class="font-weight-bold font-size-14 text-black mb-1"
          style="color: #6d6e70"
        >
          {{ addressFormatted }}
        </h3>

        <a :href="`mailto:${getValueFromSource(entity, 'information.email')}`">
          {{ getValueFromSource(entity, "information.email") }}
        </a>
        <br />

        <a
          class="tel-link"
          :href="`tel:${getValueFromSource(
            entity,
            'information.phones[0].number'
          )}`"
        >
          <a-icon type="phone" />
          {{ getValueFromSource(entity, "information.phones[0].number") }}
        </a>
        <div>
          <a-rate
            class="text-gold-colored"
            :value="getValueFromSource(entity, 'ratings.total_avg', 0)"
            :disabled="true"
          />
        </div>
        <a-divider />
        <p
          class="font-weight-light font-size-15 mb-1"
          style="color: #6d6e70; line-height: 27px"
        >
          {{ getValueFromSource(entity, "information.description") }}
        </p>

        <b-embed
          type="iframe"
          aspect="16by9"
          :src="youtubeVideoLink"
          allowfullscreen
        ></b-embed>
        <a-divider />

        <div class="col-lg-8 col-md-8 col-sm-8">
          <div
            class="e-sides e-sides-featured"
            style="display: flex"
            v-if="media"
          >
            <a-card hoverable class="featured" @click="() => showImg(0)">
              <img
                slot="cover"
                alt="example"
                width="180"
                height="150"
                :src="getMedia(media.featured_1)"
              />
            </a-card>

            <a-card hoverable class="featured" @click="() => showImg(1)">
              <img
                slot="cover"
                alt="example"
                width="180"
                height="150"
                :src="getMedia(media.featured_2)"
              />
            </a-card>

            <a-card hoverable class="featured" @click="() => showImg(2)">
              <img
                slot="cover"
                alt="example"
                width="180"
                height="150"
                :src="getMedia(media.featured_3)"
              />
            </a-card>

            <vue-easy-lightbox
              :visible="visible"
              :imgs="attachedImages"
              :index="index"
              @hide="handleHide"
            ></vue-easy-lightbox>
          </div>
        </div>
      </div>
    </div>

    <div class="e-main">
      <div class="e-sides">
        <tourist-preview-details-option
          v-if="!isAnonymouse"
          :entity_type="entity_type"
          :entity_id="entity_id"
        />

        <anonymouse-facility-preview-details-option
          v-if="isAnonymouse"
          :entity_type="entity_type"
          :entity_id="entity_id"
          :base_url="base_url"
        />
        <a-divider />

        <div
          v-if="!isAnonymouse && showReviewsLink"
          class="e-main text-dark"
          style="cursor: pointer"
          @click="onViewReviewsClicked"
        >
          <div class="item">
            <span>Reviews & Feedback</span>
          </div>
          <div class="leftChe">
            <i class="fe fe-chevron-right item text-gold-colored"></i>
          </div>
        </div>

        <div
          class="e-main text-dark"
          style="cursor: pointer"
          @click="onViewTermsAndConditions"
        >
          <div class="item">
            <span>Terms & Conditions</span>
          </div>
          <div class="leftChe">
            <i class="fe fe-chevron-right item text-gold-colored"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "lodash";

import noImage from "@/assets/images/no-image.jpg";
import VueEasyLightbox from "vue-easy-lightbox";

import TouristPreviewDetailsOption from "@/components/facilities/TouristPreviewDetailsOption.vue";
import AnonymouseFacilityPreviewDetailsOption from "@/components/facilities/AnonymouseFacilityPreviewDetailsOption.vue";
import TermsAndConditions from "@/components/shared/TermsAndConditions.vue";

export default {
  components: {
    TouristPreviewDetailsOption,
    AnonymouseFacilityPreviewDetailsOption,

    TermsAndConditions,
    VueEasyLightbox,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
    base_url: {
      type: String,
      required: false,
      default: "",
    },
    isAnonymouse: {
      type: Boolean,
      default: false,
    },
    showReviewsLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      showTermsAndConditions: false,
      index: 0,
    };
  },
  computed: {
    media() {
      return get(this, "entity.media", {});
    },
    attachedImages() {
      const media_1 = get(this.media, "featured_1", {});
      const media_2 = get(this.media, "featured_2", {});
      const media_3 = get(this.media, "featured_3", {});
      return [
        { src: media_1.path, title: media_1.name },
        { src: media_2.path, title: media_2.name },
        { src: media_3.path, title: media_3.name },
      ];
    },
    youtubeVideoLink() {
      const has_link = this.getValueFromSource(
        this.entity,
        "information.youtube_video_link",
        ""
      );

      let video_link = "";

      if (has_link.includes("watch")) {
        video_link = has_link;
      } else if (has_link.includes("youtu.be")) {
        video_link = has_link.replace("https://youtu.be/", "watch?v=");
      } else {
        video_link = "https://www.youtube.com/watch?v=z9zuWgLA3QU";
      }

      let video_id = video_link.split("v=")[1];

      const ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }

      return `https://www.youtube.com/embed/${video_id}`;
    },
    addressFormatted() {
      const address = get(this.entity, "information.address", {
        city: {},
        country: {},
      });
      return `${get(address, "city.name", "")}, ${get(
        address,
        "country.name",
        ""
      )}`;
    },
    entityPhone() {
      if (this.getValueFromSource(this.entity, "information.phones[0]")) {
        const phone = this.getValueFromSource(
          this.entity,
          "information.phones[0]"
        );
        return `+${phone.code} ${phone.number}`;
      }
      return "";
    },
    entity_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getMedia(media) {
      return get(media, "path", noImage);
    },
    onViewReviewsClicked() {
      this.$router.push(`${this.$route.path}/reviews`);
    },
    onViewTermsAndConditions() {
      this.showTermsAndConditions = !this.showTermsAndConditions;
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    onImgLoad() {
      this.imagesloaded += 1;
      if (this.imagesloaded === this.attachments.length) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.e-main {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}
.e-sides {
  flex: 50%;
  /* padding: 15px; */
}
.e-sides-featured {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.item {
  padding-right: 10px;
  margin: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
img {
  border-radius: 5px !important;
}
.leftChe {
  font-size: 28px;
  float: right;
}
.featured {
  width: 180px;
  margin: 5px;
  border-radius: 10px;
  height: 150px;
}
.text-gold-colored {
  color: #faad15;
}
.tel-link {
  display: inline-block;
  background-color: #faad15;
  text-align: center;
  color: white;
  padding: 5px 20px;
}
</style>
